export default () => ({
    multiSelect: null,
    select: null,
    selectedElements: [],

    init() {
        this.initMultiSelect();

        this.select.addEventListener('change', event => this.handleSelect(event));

        window.Livewire.on('product-search:updated', () => this.initMultiSelect());
    },

    initMultiSelect() {
        this.multiSelect = this.$el.querySelector('select[multiple]');
        this.select = this.$el.querySelector('select:not([multiple])');

        if (!this.select || !this.multiSelect) {
            return;
        }

        this.setSelectOptions();
        this.setSelected();
    },

    handleSelect(event) {
        if (!this.select || !this.multiSelect) {
            return;
        }

        for (let i = 0; i < this.multiSelect.options.length; i++) {
            if (event.target.value === this.multiSelect.options[i].value) {
                this.multiSelect.options[i].selected = true;

                this.selectedElements.push(this.multiSelect.options[i].text);
            }
        }

        this.multiSelect.dispatchEvent(new Event('change', { bubbles: true }));

        this.setSelectOptions();
    },

    setSelected() {
        this.selectedElements = [];

        for (let i = 0; i < this.multiSelect.options.length; i++) {
            if (this.multiSelect.options[i].selected) {
                this.selectedElements.push(this.multiSelect.options[i].text);
            }
        }
    },

    setSelectOptions() {
        this.select.innerHTML = '';

        const elem = document.createElement('option');
        elem.value = null;
        elem.text = 'Bitte wählen…';
        this.select.add(elem);

        for (let i = 0; i < this.multiSelect.options.length; i++) {
            if (!this.multiSelect.options[i].selected && !this.multiSelect.options[i].disabled) {
                const elem = document.createElement('option');
                elem.value = this.multiSelect.options[i].value;
                elem.text = this.multiSelect.options[i].text;
                this.select.add(elem);
            }
        }
    },

    remove(event) {
        const button = event.target.nodeName === 'BUTTON' ? event.target : event.target.closest('button');
        const option = button.querySelector('span').innerText;
        this.selectedElements.splice(
            this.selectedElements.findIndex(elem => elem === option),
            1
        );

        for (let i = 0; i < this.multiSelect.options.length; i++) {
            if (option === this.multiSelect.options[i].text) {
                this.multiSelect.options[i].selected = false;
            }
        }

        this.multiSelect.dispatchEvent(new Event('change', { bubbles: true }));

        this.setSelectOptions();
    },
});
